<template>
  <div class="registration-start">
    <LayoutViewWrapperNarrow>
      <div class="content">
        <BaseH1 :short="true">Rejestracja</BaseH1>
        <TextDescription :text="'Kontynuj przez'" />
        <AuthRegistrationBlock />
      </div>
    </LayoutViewWrapperNarrow>
  </div>
</template>

<script>
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import LayoutViewWrapperNarrow from "@/layouts/LayoutViewWrapperNarrow.vue";
import AuthRegistrationBlock from "@/components/Auth/Registration/AuthRegistrationBlock.vue";

export default {
  components: {
    LayoutViewWrapperNarrow,
    BaseH1,
    TextDescription,
    AuthRegistrationBlock,
  },
};
</script>

<style scoped>
.registration-start {
  padding-bottom: var(--header-default--height);
}
.content {
  margin: auto;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  row-gap: 70px;
}

@media (max-width: 1200px) {
  .registration-start {
    padding-bottom: 0px;
  }
  .content {
    margin: auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 70px;
  }
}
</style>
